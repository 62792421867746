import { gql } from 'graphql-tag';

export const GET_AVAILABLE_ATTRIBUTES_AND_METRICS = gql`
  query getAvailableAttributesAndMetrics($domain: String!) {
    getAvailableAttributes(domain: $domain) {
      id
      valueType
      values
      attributeGroup
      sqlColumn
      name
      description
      cardinality
      attributeSubGroups
      classification
      tags
    }
    getAvailableMetrics(domain: $domain) {
      id
      name
      description
      valueType
      ownedBy
      calculation
      datasourceIds
      subcategories
      metricColumns
      classification
      tags
      intradaySql
      dailySql
      weeklySql
      monthlySql
      quarterlySql
      yearlySql
      ttmSql
      t3mSql
      t6mSql
      ytdSql
      pySql
      pmSql
      pqSql
    }
  }
`;

export const GET_REPORT_ODM = gql`
  query getOdmForReport($query: ReportOdmQuery!) {
    getOdmForReport(query: $query) {
      site
      metric
      timespan
    }
  }
`;

export const USERS_FAVORITE_REPORTS = gql`
  query usersFavoriteReports {
    usersFavoriteReports
  }
`;

export const GET_REPORT_TEMPLATES_IN_REPORT_GROUP = gql`
  query getReportTemplatesInReportGroup($reportGroupId: String!) {
    getReportTemplatesInReportGroup(reportGroupId: $reportGroupId) {
      id
      name
      domain
      version
      filtersV2
      selectedGroupingAttributeIds
    }
  }
`;
