/* eslint-disable @typescript-eslint/no-redeclare */
import {
  PxtTelemetryWebClient,
  clientFactory,
} from '@amzn/pxt-telemetry-web-client';
import { TelemetrySchema } from '@clarity-website/common/telemetry/TelemetryUtils';
import { getAppConfig } from '@clarity-website/config/useAppConfig';

// This is a singleton class that holds the telemetry client
export class Telemetry {
  private static _client: PxtTelemetryWebClient;

  static get client() {
    if (!this._client) {
      const config = getAppConfig();
      const namespace =
        process.env.NODE_ENV === 'production'
          ? config?.PXTTelemetry?.namespace ?? 'clarity-test'
          : 'clarity-test';
      this._client = clientFactory(namespace);
    }
    return this._client;
  }

  static get imperativeSchema() {
    return process.env.NODE_ENV !== 'production'
      ? TelemetrySchema.localTestSchema
      : TelemetrySchema.common;
  }
}
