import ClientMethods, {
  DeleteRequestParams,
  GetRequestParams,
  PostRequestParams,
  RestRequestParams,
} from '@clarity-website/lib/clients/ClientMethods';
import {
  getAPIUrlPrefix,
  isLoadingFromHRSCDomain,
} from '@clarity-website/utils/hrsc-general-utils';
import { GraphQLClient } from 'graphql-request';
import type { RequestDocument, Variables } from 'graphql-request/dist/types';

export type GqlRequestParams = {
  request: RequestDocument;
  variables?: Variables;
};

const APIUrlPrefix = getAPIUrlPrefix();

const urls: Record<string, string> = {
  test: isLoadingFromHRSCDomain()
    ? 'http://localhost:3009/clarity-api/api/graphql'
    : 'http://localhost:3000/api/graphql',
};

export default class ClarityClient {
  private gqlClient: GraphQLClient;

  constructor() {
    this.gqlClient = new GraphQLClient(
      urls[process.env.NODE_ENV!] || `${APIUrlPrefix}/api/graphql`,
      {
        fetch: (input: string, init?: RequestInit) =>
          fetch(input, { ...init, referrerPolicy: 'origin' }),
      },
    );
  }

  async rest<T = any>({
    path,
    init,
    abortController,
    timeout,
    retries,
    traceId = '',
  }: RestRequestParams): Promise<T> {
    ClientMethods.validatePath(path);
    const requestPath =
      path === '/processSOTQuestion'
        ? `${APIUrlPrefix}${path}`
        : `${APIUrlPrefix}/api${path}`;

    return ClientMethods.fetch<T>({
      input: requestPath,
      init,
      abortController,
      timeout,
      retries,
      traceId,
      disableAuthRetries: isLoadingFromHRSCDomain(), // disable auth retry when Clarity MFE is loaded on HRSC
    });
  }

  async post<T = any>({
    path,
    payload,
    abortController,
    timeout,
    retries,
  }: PostRequestParams): Promise<T> {
    return this.rest<T>({
      path,
      init: {
        body: JSON.stringify(payload),
        method: 'POST',
      },
      abortController,
      timeout,
      retries,
    });
  }

  async delete<T = any>({
    path,
    abortController,
    timeout,
    retries,
  }: DeleteRequestParams): Promise<T> {
    return this.rest<T>({
      path,
      init: {
        method: 'DELETE',
      },
      abortController,
      timeout,
      retries,
    });
  }

  async get<T = any>({
    path,
    abortController,
    timeout,
    retries,
  }: GetRequestParams): Promise<T> {
    return this.rest<T>({
      path,
      init: {
        method: 'GET',
      },
      abortController,
      timeout,
      retries,
    });
  }

  async gql<T = any>(params: GqlRequestParams): Promise<T> {
    return ClientMethods.gql<T>(this.gqlClient!, params);
  }
}
