import { RegisterSchemaLocally } from '@amzn/pxt-telemetry-schema-registry-typescript';

/**
 * This is a replica of the clarity common schema registered with PXT telemetry here:
 * https://code.amazon.com/packages/PxtTelemetrySchemaRegistry/trees/mainline/--/src/registry/event/clarity/common/
 * It can be used during development to validate that new clickstream events are compatible with the common schema locally.
 * This interface should be updated to reflect the latest version of the common schema.
 */

export class CommonTestSchema {
  // Required
  appName: string = '';
  appVersion: string = '';
  domain: string = '';
  uriBasePath: string = '';
  eventType: string = '';
  // Optional
  acknowledged?: string = '';
  action?: string = '';
  actionCategory?: string = '';
  benchmarkValue?: string = '';
  bulkUpdatedTables?: string = '';
  cachedStatus?: string = '';
  category?: string = '';
  changeType?: string = '';
  chartType?: string = '';
  conversationId?: string = '';
  country?: string = '';
  currentStep?: string = '';
  dashboard?: string = '';
  dataset?: string = '';
  displayStyle?: string = '';
  dynamicInsertType?: string = '';
  dynamicInsertUser?: string = '';
  dynamicInsertValue?: string = '';
  error?: string = '';
  errorMessage?: string = '';
  errorType?: string = '';
  estimatedDataSize?: number = 0;
  event_label?: string = '';
  filterName?: string = '';
  filterValue?: string = '';
  firstRowSize?: number = 0;
  from?: string = '';
  goalId?: string = '';
  goalName?: string = '';
  goalToggle?: string = '';
  header?: string = '';
  helpTabSelected?: string = '';
  hoverDurationMs?: string = '';
  id?: string = '';
  initiatingEvent?: string = '';
  intersectionRatio?: string = '';
  isCached?: string = '';
  isOutlier?: string = '';
  isSharedReport?: boolean = false;
  label?: string = '';
  leader?: string = '';
  link?: string = '';
  location?: string = '';
  login?: string = '';
  messageId?: string = '';
  messageType?: string = '';
  metric?: string = '';
  metricCategory?: string = '';
  metricId?: string = '';
  metricIds?: string = '';
  metricName?: string = '';
  newGoalName?: string = '';
  odmFalseNegative?: string = '';
  odmFalsePositive?: string = '';
  odmNumOutliers?: string = '';
  outliers?: string = '';
  page?: number = 0;
  parentId?: string = '';
  parentReportGroupId?: string = '';
  parentReportId?: string = '';
  payload?: string = '';
  positionOfTableInSheet?: number = 0;
  rateLimiterStatus?: string = '';
  reportGroupId?: string = '';
  reportGroupName?: string = '';
  reportId?: string = '';
  reportName?: string = '';
  reportStatus?: string = '';
  reportSubType?: string = '';
  reportType?: string = '';
  resultStatus?: string = '';
  rootReportTemplateId?: string = '';
  rowNumber?: string = '';
  rows?: number = 0;
  saveType?: string = '';
  scope?: string = '';
  score?: string = '';
  selectedRating?: string = '';
  selectedOptions?: string[] = [];
  optionalData?: string = '';
  selection?: string = '';
  sentimentValue?: string = '';
  sheetId?: string = '';
  sheetName?: string = '';
  showDeltaColumn?: boolean = false;
  showGoalColumn?: boolean = false;
  showNotificationList?: boolean = false;
  site?: string = '';
  sourceWidgetDescription?: string = '';
  subject?: string = '';
  subjects?: string = '';
  systemMessageId?: string = '';
  tab?: string = '';
  tabId?: string = '';
  tableColumns?: number = 0;
  tableId?: string = '';
  tableRows?: number = 0;
  tabName?: string = '';
  target?: string = '';
  timespan?: string = '';
  timespanTuples?: number = 0;
  title?: string = '';
  toggle?: string = '';
  topicName?: string = '';
  totalDatapoints?: string = '';
  traceId?: string = '';
  type?: string = '';
  userClick?: string = '';
  userInput?: string = '';
  value?: string = '';
  view?: string = '';
  widgetDescription?: string = '';
  widgetKind?: string = '';
  widgetSubject?: string = '';
  widgetType?: string = '';
  x?: number = 0;
  xValue?: string = '';
  y?: number = 0;
  yValues?: string = '';
  delta?: string = '';
  latency?: number = 0;
  numberOfBulkUpdatedTables?: number = 0;
  numDataRows?: number = 0;
  pageLoadedTime?: number = 0;
  additionalData?: string = '';
  employeeLogin?: string = '';
  pageName?: string;
}

export const requiredProperties = [
  'appName',
  'appVersion',
  'domain',
  'uriBasePath',
  'eventType',
];

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface Common extends CommonTestSchema {}

declare module '@amzn/pxt-telemetry-schema-registry-typescript' {
  // eslint-disable-next-line @typescript-eslint/no-empty-object-type
  interface EventSchemaRegistry
    extends RegisterSchemaLocally<
      'arn:pxt:telemetry:event:clarity:common:2.0.0',
      Common
    > {}
}
