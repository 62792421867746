import AppTitle from '@clarity-website/app/AppTitle';
import ConfigurationContext from '@clarity-website/app/ConfigurationContext';
import usePrefetchData from '@clarity-website/app/usePrefetchData';
import { AnalyticsScope } from '@clarity-website/common/AnalyticsScope';
import QueryBoundary from '@clarity-website/common/QueryBoundary';
import useTelemetryConfig from '@clarity-website/common/telemetry/useTelemetryConfig';
import { currentUserStateAtom } from '@clarity-website/common/userProviderAtoms';
import { LayoutProvider } from '@clarity-website/layout/layout-context';
import PageNotFound from '@clarity-website/pages/PageNotFound';
import { useInitRum } from '@clarity-website/rum';
import GlobalStyles from '@clarity-website/utils/global-styles';
import theme from '@clarity-website/utils/theme';
import { useAtomValue } from 'jotai';
import { ReactElement, Suspense, lazy } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Helmet } from 'react-helmet';
import { Route, Switch } from 'react-router-dom';
import BarLoader from 'react-spinners/BarLoader';

const ClarityApp = lazy(
  () =>
    import(
      /* webpackChunkName: "clarity-main-app" */ '@clarity-website/app/ClarityApp'
    ),
);
const MaintenanceApp = lazy(
  () =>
    import(
      /* webpackChunkName: "clarity-maintenance-app" */ '@clarity-website/app/MaintenanceApp'
    ),
);
const HelpApp = lazy(
  () =>
    import(
      /* webpackChunkName: "clarity-help-app" */ '@clarity-website/app/HelpApp'
    ),
);
const ClarityAssistDragAndDrop = lazy(
  () =>
    import(
      /* webpackChunkName: "clarity-assist-app" */ '@clarity-website/app/ClarityAssistDragAndDrop'
    ),
);

const PXT_TELEMETRY_TEST_ENVIRONMENT =
  'https://gamma.telemetry.pxt.amazon.dev/';

function App(): ReactElement {
  usePrefetchData();
  useInitRum();

  const { user } = useAtomValue(currentUserStateAtom);
  const { pxtTelemetryEndpoint, pxtTelemetryNamespace } = useTelemetryConfig();

  return (
    <div data-pxt-telemetry-namespace={pxtTelemetryNamespace}>
      <AppTitle />
      <GlobalStyles />
      <QueryBoundary
        errorScope="appRouter"
        fallback={
          <BarLoader loading color={theme.color.primary60} width="100%" />
        }
      >
        {user && (
          <Helmet>
            <script src={pxtTelemetryEndpoint} />
          </Helmet>
        )}
        <ConfigurationContext>
          <LayoutProvider>
            <>
              <Switch>
                <Route path="/maintenance">
                  <AnalyticsScope>
                    <Route path="/maintenance/" component={MaintenanceApp} />
                  </AnalyticsScope>
                </Route>
                <Route path="/help">
                  <AnalyticsScope>
                    <Route path="/help/" component={HelpApp} />
                  </AnalyticsScope>
                </Route>
                <Route component={ClarityApp} />
                <Route component={PageNotFound} />
              </Switch>
              <ErrorBoundary fallback={<span hidden />}>
                <Suspense fallback={<span hidden />}>
                  <ClarityAssistDragAndDrop />
                </Suspense>
              </ErrorBoundary>
            </>
          </LayoutProvider>
        </ConfigurationContext>
      </QueryBoundary>
    </div>
  );
}

export default App;
