import {
  DisplayStyleType,
  MetadataType,
  PeriodType,
  ValueType,
} from '@amzn/claritygqllambda';
import { MetadataLayout } from '@clarity-website/react-table-data-handler/common/types';
import { UnprocessedRawRowValues } from '@clarity-website/reports/useFetchRawReport';

export const DISPLAY_STYLE_HASH_KEYS = {
  // metadataOnRow#metadataOnColumn
  MetricOnColumn: `${MetadataType.Attribute}#${[
    MetadataType.Timespan,
    MetadataType.Metric,
  ]}`,
  AttributeOnColumn: `${MetadataType.Attribute}#${[
    MetadataType.Timespan,
    MetadataType.Attribute,
  ]}`,
  MetricOnRow: `${[MetadataType.Attribute, MetadataType.Metric]}#${
    MetadataType.Timespan
  }`,
  AttrAndTimespanOnRow: `${[MetadataType.Attribute, MetadataType.Timespan]}`,
  BackendPivoted: 'BackendPivoted',
};

export const METRIC_ON_ROW_COLUMN_ID = 'metrics';
export const TIMESPAN_ON_ROW_COLUMN_ID = 'timespan';

export const defaultMetadataLayout: MetadataLayout = {
  [DisplayStyleType.MetricOnColumn]: {
    rows: [MetadataType.Attribute],
    columns: [MetadataType.Timespan, MetadataType.Metric],
  },
  Attribute_On_Column: {
    rows: [MetadataType.Attribute],
    columns: [MetadataType.Timespan, MetadataType.Attribute],
  },
  [DisplayStyleType.MetricOnRow]: {
    rows: [MetadataType.Attribute, MetadataType.Metric],
    columns: [MetadataType.Timespan],
  },
  [DisplayStyleType.RawData]: {
    rows: [MetadataType.Attribute, MetadataType.Timespan],
    columns: [],
  },
  [DisplayStyleType.ReactTable]: {
    rows: [],
    columns: [],
  },
};

export const PARTIAL_SUBTOTAL_REGEX = /^Sum total for/;

export const TIMESPAN_PREFIX_PERIOD_TYPE_MAP: { [key: string]: PeriodType } = {
  i: PeriodType.Intraday,
  d: PeriodType.Daily,
  w: PeriodType.Weekly,
  m: PeriodType.Monthly,
  pm: PeriodType.Pm,
  t3m: PeriodType.T3m,
  t6m: PeriodType.T6m,
  ttm: PeriodType.Ttm,
  q: PeriodType.Quarterly,
  ytd: PeriodType.Ytd,
  y: PeriodType.Yearly,
  py: PeriodType.Py,
  pq: PeriodType.Pq,
};

// Only expected to apply to Last x Years, Current YTD, Current QTD
export const TIMESPAN_PREFIX_MAP: {
  [key: string]: { fillValue: UnprocessedRawRowValues; sqlKey: string };
} = {
  q: {
    fillValue: { value: 'N/A for cal quarter', valueType: ValueType.String },
    sqlKey: 'quarterlySql',
  },
  y: {
    fillValue: { value: 'N/A for cal year', valueType: ValueType.String },
    sqlKey: 'yearlySql',
  },
  pq: {
    fillValue: { value: 'N/A for *Perf quarter', valueType: ValueType.String },
    sqlKey: 'pqSql',
  },
  py: {
    fillValue: { value: 'N/A for *Perf year', valueType: ValueType.String },
    sqlKey: 'pySql',
  },
};

// this will match "xxx_12345678", "xx_12345678", "x_12345678", and variations
export const TIMESPAN_COLUMN_REGEX = new RegExp(/[a-z0-9]{1,3}_[0-9]{8}/gm);

// this will match "Pm", "Yearly", and variations
export const TIMESPAN_ID_REGEX = new RegExp('(?<=:)[a-zA-Z]+(?=:)');
