import { gql } from 'graphql-tag';
import { innerJoin } from 'ramda';

export interface Actor {
  actor: string;
  type: ActorType;
}

export enum ActorType {
  ALIAS = 'ALIAS',
  ANT = 'ANT',
}

export enum ActorRole {
  OWNER = 'OWNER',
  READER = 'READER',
}

export interface Principal {
  actor: string;
  type: ActorType;
  role: ActorRole;
}

export interface GetUserGroupsInterface {
  getUserGroups: Actor[];
}

export const GET_USER_GROUPS = gql`
  query getUserGroups {
    getUserGroups {
      actor
      type
    }
  }
`;

export const isReportGroupOwner = (
  userMemberships: Actor[],
  principals?: Principal[],
) => {
  if (!principals || !principals.length) {
    return false;
  }
  const ownerActors: Actor[] = principals.filter(
    (principal) => principal.role === ActorRole.OWNER,
  );

  const intersectedList = innerJoin(
    (a, b) => a.type === b.type && a.actor === b.actor,
    ownerActors,
    userMemberships,
  );
  return intersectedList.length > 0;
};

export const isReportPrincipal = (
  userMemberships: Actor[],
  principals?: Principal[],
) => {
  if (!principals || !principals.length) {
    return false;
  }

  const intersectedList = innerJoin(
    (a, b) => a.type === b.type && a.actor === b.actor,
    principals,
    userMemberships,
  );
  return intersectedList.length > 0;
};
