import {
  Button,
  ButtonSize,
  ButtonVariant,
} from '@amzn/stencil-react-components/button';
import { Col, Flex, Row } from '@amzn/stencil-react-components/layout';
import { Text } from '@amzn/stencil-react-components/text';
import { useFeatureFlagToggles } from '@clarity-website/app/ConfigurationContext';
import { ReactComponent as ReportTooLargeIcon } from '@clarity-website/assets/svg/report-too-large.svg';

interface Props {
  openReportFlyout?: () => void;
  exportTableExcel?: () => void;
}

const SMALL_SCREEN_THRESHOLD = 500;
const MAX_WIDTH_TEXT_SECTION = 290;

export default function ReportTooLargeErrorPage({
  openReportFlyout,
  exportTableExcel,
}: Props) {
  const isSmall = window.innerWidth < SMALL_SCREEN_THRESHOLD;
  const flexDirection = isSmall ? 'column-reverse' : 'row';

  const { isFeatureFlagToggleOnAndOnAllowList: canUseOfflineDownloading } =
    useFeatureFlagToggles('offline-downloading');

  // Temporary
  let text = 'Add some filters to reduce its size';
  if (canUseOfflineDownloading) {
    text += ', or get a link to download to Excel';
  }

  return (
    <Flex alignItems="center" gridGap="S300" flexDirection={flexDirection}>
      <Col gridGap="S300" maxWidth={MAX_WIDTH_TEXT_SECTION}>
        <Text fontSize="T300">This report is quite large</Text>
        <Text fontSize="T100">{text}</Text>
        <Row columnGap="S300">
          <Button
            onClick={openReportFlyout}
            variant={ButtonVariant.Primary}
            size={ButtonSize.Small}
          >
            Add filters
          </Button>
          {canUseOfflineDownloading && (
            <Button onClick={exportTableExcel} size={ButtonSize.Small}>
              Get download link
            </Button>
          )}
        </Row>
      </Col>
      <Col alignItems="center">
        <ReportTooLargeIcon />
      </Col>
    </Flex>
  );
}
