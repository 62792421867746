import {
  SendImperativeInternal,
  SendImperativePropsExplicitPath,
  TelemetryPath,
} from '@amzn/pxt-telemetry-web-client';
import { shouldEnableAnalyticsForUser } from '@clarity-website/common/Analytics';
import { setPxtTelemetryContext } from '@clarity-website/common/telemetry/TelemetryUtils';
import {
  User,
  currentUserStateAtom,
} from '@clarity-website/common/userProviderAtoms';
import { AppConfig } from '@clarity-website/config/stage-config';
import useAppConfig from '@clarity-website/config/useAppConfig';
import { Actor } from '@clarity-website/reports/share-modal/ShareReportModal';
import { useAtomValue } from 'jotai';
import { useEffect } from 'react';

declare global {
  interface Window {
    PxtTelemetry: {
      sendImperative: (...events: SendImperativeInternal[]) => void;
      sendImperativeExplicitPath: (
        ...events: SendImperativePropsExplicitPath[]
      ) => void;
      markPageImpression: (
        timestampRelativeToOriginOverride?: DOMHighResTimeStamp,
      ) => void;
      collectPaths: (element: Element) => TelemetryPath;
      describeActor: (actor: Actor) => void;
    };
  }
}

const configurePxtTelemetry = (userLogin: string) => {
  let originalPxtTelemetry = window.PxtTelemetry;
  Object.defineProperty(window, 'PxtTelemetry', {
    configurable: true,
    set(value) {
      originalPxtTelemetry = value;
      if (value?.describeActor) {
        setPxtTelemetryContext(userLogin);
      }
    },
    get() {
      return originalPxtTelemetry;
    },
  });
};

export function getPxtTelemetryConfig(user?: User, config?: AppConfig) {
  const PXT_TELEMETRY_TEST_ENVIRONMENT =
    'https://gamma.telemetry.pxt.amazon.dev/';
  let pxtTelemetryEndpoint = PXT_TELEMETRY_TEST_ENVIRONMENT;
  let pxtTelemetryNamespace = 'clarity-test';

  if (config?.PXTTelemetry && process.env.NODE_ENV === 'production') {
    pxtTelemetryEndpoint = config.PXTTelemetry.endpoint;
    pxtTelemetryNamespace = config.PXTTelemetry.namespace;
  }

  if (
    !shouldEnableAnalyticsForUser(user, config!.Analytics!.countriesToDisable)
  ) {
    pxtTelemetryEndpoint = '';
  }

  return {
    pxtTelemetryEndpoint,
    pxtTelemetryNamespace,
  };
}

export default function useTelemetryConfig() {
  const { data: config } = useAppConfig();
  const { user } = useAtomValue(currentUserStateAtom);

  useEffect(() => {
    if (user?.login) {
      configurePxtTelemetry(user.login);
    }
  }, [user?.login]);

  return getPxtTelemetryConfig(user, config);
}
